ol {
list-style-type: none;
}

ul {
list-style-type: none;
}

.error {
color: red;
}
